import Resources from '../Resources/Resources.js'

import './Sidenav.css'

function Sidenav() {
    return (
        <>
            <Resources />
            <p>Communities link</p>
            <p>Best of reddit link</p>
            <p>Topics link</p>
            <Resources />
            <p>Communities link</p>
            <p>Best of reddit link</p>
            <p>Topics link</p>
            <Resources />
            <p>Communities link</p>
            <p>Best of reddit link</p>
            <p>Topics link</p>
        </>
    )
}

export default Sidenav