function Resources() {
    return (
        <section>
            <p>link to about</p>
            <p>link to advertise</p>
            <p>link to help</p>
        </section>
    )
}

export default Resources;